<template>
  <div v-if="showItem">
    <div class="mx-015" :id="item.slug+'-listed-'+id">
      <a :href="pathOfItemSettings" v-if="hasAccessToSettings">
        <b-iconstack font-scale="1" style="color:#fff">
          <b-icon stacked icon="exclamation-circle" scale=".9"></b-icon>
        </b-iconstack>
      </a>
      <b-iconstack v-else font-scale="1" style="color:#fff">
        <b-icon stacked icon="exclamation-circle" scale=".9"></b-icon>
      </b-iconstack>
    </div>
    <b-tooltip :target="item.slug+'-listed-'+id">
      <div v-if="(item.searchable === false
      && (item.type === 'project'
      || (this.item.type === 'page' && this.parentItemType === 'folder')))">
        <small> {{ $t('webmag.hiddenFromSearchEngines') }}</small>
      </div>
      <div v-if="(item.listed === false && item.type === 'project')">
        <small> {{ $t('webmag.listedOnOverviewPage') }}</small>
      </div>
      <div v-if="(item.listed === false && item.type === 'page')">
        <small> {{ $t('webmag.pageHiddenFromProject') }}</small>
      </div>
      <div v-if="(item.isSharedTemplate === true)">
        <small> {{ $t('webmag.isSharedTemplate') }}</small>
      </div>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: 'ListedStatusIcons',
  props: ['id', 'item', 'parentItemType', 'hasAccessToSettings'],
  data() {
    return {
      pathOfItemSettings: null,
    };
  },
  computed: {
    showItem() {
      if (this.item.published) {
        if (this.item.listed === false) {
          return true;
        }
        if (this.item.searchable === false) {
          return (this.item.type === 'project' || (this.item.type === 'page' && this.parentItemType === 'folder'));
        }
        if (this.item.isSharedTemplate === true) {
          return true;
        }
      }
      return false;
    },
  },
  created() {
    this.pathOfItemSettings = (this.$route.path !== '/')
      ? `${this.$route.path}/${this.item.slug}/${this.item.type}-settings`
      : `/${this.item.slug}/${this.item.type}-settings`;
  },
};
</script>

<style scoped>

</style>
