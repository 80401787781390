<template>
  <div v-if="item.listed === false">
    <div class="mx-015" :id="item.slug+'-listed-'+id" :class="{ 'mt-1': hasMarginTop }">
      <a :href="pathOfItemSettings" v-if="hasAccessToSettings">
        <b-iconstack font-scale="1" style="color:#fff">
          <b-icon stacked icon="exclamation-circle" scale=".9"></b-icon>
        </b-iconstack>
      </a>
      <b-iconstack v-else font-scale="1" style="color:#fff">
        <b-icon stacked icon="exclamation-circle" scale=".9"></b-icon>
      </b-iconstack>
    </div>
    <b-tooltip :target="item.slug+'-listed-'+id">
      <small> {{ $t('webmag.listedOnOverviewPageSnippet') }} </small>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: 'ListedStatusIcons',
  props: ['id', 'item', 'hasAccessToSettings'],
  computed: {
    hasMarginTop() {
      return (this.id === 'card-item');
    },
  },
  data() {
    return {
      pathOfItemSettings: null,
    };
  },
  created() {
    this.pathOfItemSettings = (this.$route.path !== '/')
      ? `${this.$route.path}/${this.item.slug}/${this.item.type}-settings`
      : `/${this.item.slug}/${this.item.type}-settings`;
  },
};
</script>

<style scoped>

</style>
