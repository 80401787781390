<template>
  <div>
    <b-dropdown
      class="context-menu"
      toggle-class="dot-button"
      no-caret
      dropup
      no-flip
      right
    >
      <template #button-content>
        <b-icon icon="three-dots-vertical" aria-hidden="true" scale="1"></b-icon>
      </template>
      <b-dropdown-item
        v-if="item.type === 'project' && item.published === true && isSysAdmin"
        @click="updateProject"
      >
        {{ $t('dropdowns.updateProject') }}
        <b-icon icon="arrow-repeat" aria-hidden="true" scale="1"></b-icon>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="item.type !== 'folder'
        && $hasPrivileges($privileges('CAN_PUBLISH_ITEMS'), isSysAdmin, item.privilegesForItem)"
        :disabled="!enablePublishButton"
        @click="openPublishModal"
      >
        {{ $t('dropdowns.publish') }}
        <b-icon icon="cloud-upload-fill" aria-hidden="true" scale="1"></b-icon>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="item.type !== 'folder'"
        :disabled="!item.published"
        @click="showShowUrlModal = true"
      >
        {{ $t('dropdowns.showUrl') }}
        <b-icon icon="link" aria-hidden="true" scale="1.1"></b-icon>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="!isTeamView && (isSysAdmin
        || $hasPrivileges($privileges('CAN_DUPLICATE_ITEMS'), isSysAdmin, item.privilegesForItem))"
        @click="openDuplicateModal"
      >
        {{ $t('dropdowns.duplicate') }}
        <b-icon icon="files" aria-hidden="true" scale="1"></b-icon>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="!isTeamView
        && $hasPrivileges($privileges('CAN_MOVE_ITEMS'), isSysAdmin, item.privilegesForItem)"
        @click="openMoveModal"
      >
        {{ $t('dropdowns.move') }}
        <b-icon icon="arrow-right-square" aria-hidden="true" scale="1"></b-icon>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="item.type !== 'folder'
        && $hasPrivileges($privileges('CAN_UNPUBLISH_ITEMS'), isSysAdmin, item.privilegesForItem)"
        :disabled="!showUnpublishButton"
        v-b-modal="'unpublish-'+item.slug"
      >
        {{ $t('dropdowns.unpublish') }}
        <b-icon icon="pause" aria-hidden="true" scale="1.2"></b-icon>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="!isTeamView && canDeleteItem"
        v-b-modal="`delete-${item.type}-${item.id}`"
      >
        {{ $t('dropdowns.delete') }}
        <b-icon icon="trash" aria-hidden="true" scale="1"></b-icon>
      </b-dropdown-item>
      <b-dropdown-item v-if="canAccessSettings" :to="linkToItemSettingsPage">
        {{ $t('dropdowns.settings') }}
        <b-icon icon="gear-fill" aria-hidden="true" scale="1"></b-icon>
      </b-dropdown-item>
    </b-dropdown>
    <modal-publish-item
      v-if="showPublishModal
        && $hasPrivileges($privileges('CAN_PUBLISH_ITEMS'), isSysAdmin, item.privilegesForItem)"
      :item="item"
      @publish-item="publishItem"
      @publish-cancel="showPublishModal = false"
    ></modal-publish-item>
    <modal-show-url
      v-if="showShowUrlModal && item.groupDomain && item.type !== 'folder'"
      :slug="item.slug"
      :group-domain="item.groupDomain"
      :slug-path="slugPath"
      :pageType="item.type"
      :itemPath="item.item_path"
      @close-modal="closeShowUrlModal"
    ></modal-show-url>
    <modal-move
      v-if="item.groupDomain
        && showMoveModal
        && $hasPrivileges($privileges('CAN_MOVE_ITEMS'), isSysAdmin, item.privilegesForItem)"
      :slug="item.slug"
      :group-domain="item.groupDomain"
      :group-slug="groupSlug"
      :slug-path-of-item="slugPath"
      :page-type="item.type"
      :item-id="item.id"
      :item-name="item.name"
      :parent-item="parentItem"
      @item-moved="movedItem"
    ></modal-move>
    <modal-duplicate
      v-if="item.groupDomain
        && showDuplicateModal
        && ($hasPrivileges($privileges('CAN_DUPLICATE_ITEMS'), isSysAdmin, item.privilegesForItem)
        || isSysAdmin)"
      :group-domain="item.groupDomain"
      :group-slug="groupSlug"
      :parent-item="parentItem"
      :item-id="item.id"
      :item-name="item.name"
      :slug="item.slug"
      :page-type="item.type"
      @item-duplicated="duplicatedItem"
    ></modal-duplicate>
    <modal-delete-item
      v-if="canDeleteItem"
      :item="item"
      @delete-item="deleteItem"
    ></modal-delete-item>
    <modal-unpublish-item
      v-if="$hasPrivileges($privileges('CAN_UNPUBLISH_ITEMS'), isSysAdmin, item.privilegesForItem)"
      :item="item"
      @unpublish-item="unPublishItem"
    ></modal-unpublish-item>
  </div>
</template>

<script>
import SetItemUrlWindowFromCache from '@/graphQlQueries/mutations/setItemUrlWindowFromCache';
import GetItemUrlWindowFromCache from '@/graphQlQueries/queries/getItemUrlWindowFromCache';

export default {
  name: 'DotDropdown',
  components: {
    ModalDeleteItem: () => import('@/components/modals/ModalDeleteItem.vue'),
    ModalMove: () => import('@/components/modals/ModalMove.vue'),
    ModalDuplicate: () => import('@/components/modals/ModalDuplicate.vue'),
    ModalPublishItem: () => import('@/components/modals/ModalPublishItem.vue'),
    ModalShowUrl: () => import('@/components/modals/ModalShowUrl.vue'),
    ModalUnpublishItem: () => import('@/components/modals/ModalUnpublishItem.vue'),
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    parentItem: {
      type: Object,
      default: null,
    },
    groupSlug: {
      type: String,
      default: null,
    },
    isProjectPublished: {
      type: Boolean,
      default: null,
    },
    isTeamView: {
      type: Boolean,
      default: false,
    },
    isGroupUser: {
      type: Boolean,
      default: true,
    },
    isSysAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showMoveModal: false,
    showDuplicateModal: false,
    showPublishModal: false,
    showShowUrlModal: false,
  }),
  computed: {
    statusOfPageVersions() {
      let publishStatus = true;
      if (this.item.type === 'project') {
        this.item.childPages.forEach((childPage) => {
          if (childPage.published
            && childPage.latest_page_version_id !== childPage.latest_published_page_version_id) {
            publishStatus = false;
          }
        });
      }
      if (this.item.type === 'page') {
        if (this.item.latestPageVersionId !== this.item.latestPublishedPageVersionId) {
          publishStatus = false;
        }
      }
      return publishStatus;
    },
    canAccessSettings() {
      let privilege = '';
      switch (this.item.type) {
        case 'folder':
          privilege = 'CAN_EDIT_FOLDER_SETTINGS';
          break;
        case 'project':
          privilege = 'CAN_EDIT_PROJECT_SETTINGS';
          break;
        case 'page':
          privilege = 'CAN_EDIT_PAGE_SETTINGS';
          break;
        default:
          privilege = '';
      }
      return this.$hasPrivileges(
        this.$privileges(privilege),
        this.isSysAdmin,
        this.item.privilegesForItem,
      );
    },
    slugPath() {
      if (this.isTeamView) {
        return `${this.item.path}`;
      }
      return (this.$route.path === '/')
        ? `/${this.item.slug}`
        : `${this.$route.path}/${this.item.slug}`;
    },
    canDeleteItem() {
      switch (this.item.type) {
        case 'folder':
          return this.$hasPrivileges(
            this.$privileges('CAN_DELETE_FOLDERS'),
            this.isSysAdmin,
            this.item.privilegesForItem,
          );
        case 'project':
          return this.$hasPrivileges(
            this.$privileges('CAN_DELETE_PROJECTS'),
            this.isSysAdmin,
            this.item.privilegesForItem,
          );
        case 'page':
          return this.$hasPrivileges(
            this.$privileges('CAN_DELETE_PAGES'),
            this.isSysAdmin,
            this.item.privilegesForItem,
          );
        default:
          return false;
      }
    },
    linkToItemSettingsPage() {
      if (this.isTeamView) {
        return `${this.item.path}/${this.item.type}-settings`;
      }
      const currentPath = (this.$route.path === '/')
        ? `/${this.item.slug}`
        : `${this.$route.path}/${this.item.slug}`;
      return `${currentPath}/${this.item.type}-settings`;
    },
    enablePublishButton() {
      if (
        (this.item.type !== 'folder' && !this.item.published && this.isProjectPublished !== false)
        || this.projectOrPagePublishedAndStatusChanged
      ) {
        return true;
      }
      return false;
    },
    showUnpublishButton() {
      return !!(this.item.published && this.item.type !== 'folder');
    },
    projectOrPagePublishedAndStatusChanged() {
      return (this.item.type !== 'folder' && this.item.published && !this.statusOfPageVersions);
    },
    projectOrPageUnPublished() {
      return (this.item.type !== 'folder' && !this.item.published);
    },
  },
  apollo: {
    showShowUrlModal: {
      query: GetItemUrlWindowFromCache,
      update(data) {
        if (data.itemUrlWindow?.openItemUrlWindow === true
          && data.itemUrlWindow?.itemPath === this.item.item_path) {
          // the show url button is pressed so call the show url modal window
          return true;
        }
        return false;
      },
      skip() {
        return !this.item.item_path;
      },
    },
  },
  methods: {
    updateProject() {
      this.$emit('item-action', 'updateProject', this.item);
    },
    closeShowUrlModal() {
      this.showShowUrlModal = false;
      this.$apollo.mutate({
        mutation: SetItemUrlWindowFromCache,
        variables: {
          openItemUrlWindow: false,
          itemPath: null,
        },
      });
    },
    publishItem() {
      this.showPublishModal = false;
      // add change status for publishing
      this.item.statusChanged = this.projectOrPagePublishedAndStatusChanged;
      this.$emit('item-action', 'publish', this.item);
    },
    unPublishItem() {
      this.$emit('item-action', 'unPublish', this.item);
    },
    deleteItem() {
      this.$emit('item-action', 'delete', this.item);
    },
    movedItem() {
      this.$emit('item-action', 'moved', null);
    },
    duplicatedItem() {
      this.showDuplicateModal = false;
      this.$emit('item-action', 'duplicated', null);
    },
    openPublishModal() {
      this.showPublishModal = true;
      if (this.showPublishModal === true) {
        this.$bvModal.show(`publish-${this.item.slug}`);
      }
    },
    openMoveModal() {
      this.showMoveModal = true;
      if (this.showMoveModal === true) {
        this.$bvModal.show(`move${this.item.slug}`);
      }
    },
    openDuplicateModal() {
      this.showDuplicateModal = true;
      if (this.showDuplicateModal === true) {
        this.$bvModal.show(`duplicate${this.item.slug}`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ ul {
  z-index: 10000;
}

/deep/ .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e0e2e4;

  svg {
    padding-left: 5px;
    margin-left: 5px;
  }
}
</style>
