var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-dropdown',{staticClass:"context-menu",attrs:{"toggle-class":"dot-button","no-caret":"","dropup":"","no-flip":"","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon',{attrs:{"icon":"three-dots-vertical","aria-hidden":"true","scale":"1"}})]},proxy:true}])},[(_vm.item.type === 'project' && _vm.item.published === true && _vm.isSysAdmin)?_c('b-dropdown-item',{on:{"click":_vm.updateProject}},[_vm._v(" "+_vm._s(_vm.$t('dropdowns.updateProject'))+" "),_c('b-icon',{attrs:{"icon":"arrow-repeat","aria-hidden":"true","scale":"1"}})],1):_vm._e(),(_vm.item.type !== 'folder'
      && _vm.$hasPrivileges(_vm.$privileges('CAN_PUBLISH_ITEMS'), _vm.isSysAdmin, _vm.item.privilegesForItem))?_c('b-dropdown-item',{attrs:{"disabled":!_vm.enablePublishButton},on:{"click":_vm.openPublishModal}},[_vm._v(" "+_vm._s(_vm.$t('dropdowns.publish'))+" "),_c('b-icon',{attrs:{"icon":"cloud-upload-fill","aria-hidden":"true","scale":"1"}})],1):_vm._e(),(_vm.item.type !== 'folder')?_c('b-dropdown-item',{attrs:{"disabled":!_vm.item.published},on:{"click":function($event){_vm.showShowUrlModal = true}}},[_vm._v(" "+_vm._s(_vm.$t('dropdowns.showUrl'))+" "),_c('b-icon',{attrs:{"icon":"link","aria-hidden":"true","scale":"1.1"}})],1):_vm._e(),(!_vm.isTeamView && (_vm.isSysAdmin
      || _vm.$hasPrivileges(_vm.$privileges('CAN_DUPLICATE_ITEMS'), _vm.isSysAdmin, _vm.item.privilegesForItem)))?_c('b-dropdown-item',{on:{"click":_vm.openDuplicateModal}},[_vm._v(" "+_vm._s(_vm.$t('dropdowns.duplicate'))+" "),_c('b-icon',{attrs:{"icon":"files","aria-hidden":"true","scale":"1"}})],1):_vm._e(),(!_vm.isTeamView
      && _vm.$hasPrivileges(_vm.$privileges('CAN_MOVE_ITEMS'), _vm.isSysAdmin, _vm.item.privilegesForItem))?_c('b-dropdown-item',{on:{"click":_vm.openMoveModal}},[_vm._v(" "+_vm._s(_vm.$t('dropdowns.move'))+" "),_c('b-icon',{attrs:{"icon":"arrow-right-square","aria-hidden":"true","scale":"1"}})],1):_vm._e(),(_vm.item.type !== 'folder'
      && _vm.$hasPrivileges(_vm.$privileges('CAN_UNPUBLISH_ITEMS'), _vm.isSysAdmin, _vm.item.privilegesForItem))?_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('unpublish-'+_vm.item.slug),expression:"'unpublish-'+item.slug"}],attrs:{"disabled":!_vm.showUnpublishButton}},[_vm._v(" "+_vm._s(_vm.$t('dropdowns.unpublish'))+" "),_c('b-icon',{attrs:{"icon":"pause","aria-hidden":"true","scale":"1.2"}})],1):_vm._e(),(!_vm.isTeamView && _vm.canDeleteItem)?_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(`delete-${_vm.item.type}-${_vm.item.id}`),expression:"`delete-${item.type}-${item.id}`"}]},[_vm._v(" "+_vm._s(_vm.$t('dropdowns.delete'))+" "),_c('b-icon',{attrs:{"icon":"trash","aria-hidden":"true","scale":"1"}})],1):_vm._e(),(_vm.canAccessSettings)?_c('b-dropdown-item',{attrs:{"to":_vm.linkToItemSettingsPage}},[_vm._v(" "+_vm._s(_vm.$t('dropdowns.settings'))+" "),_c('b-icon',{attrs:{"icon":"gear-fill","aria-hidden":"true","scale":"1"}})],1):_vm._e()],1),(_vm.showPublishModal
      && _vm.$hasPrivileges(_vm.$privileges('CAN_PUBLISH_ITEMS'), _vm.isSysAdmin, _vm.item.privilegesForItem))?_c('modal-publish-item',{attrs:{"item":_vm.item},on:{"publish-item":_vm.publishItem,"publish-cancel":function($event){_vm.showPublishModal = false}}}):_vm._e(),(_vm.showShowUrlModal && _vm.item.groupDomain && _vm.item.type !== 'folder')?_c('modal-show-url',{attrs:{"slug":_vm.item.slug,"group-domain":_vm.item.groupDomain,"slug-path":_vm.slugPath,"pageType":_vm.item.type,"itemPath":_vm.item.item_path},on:{"close-modal":_vm.closeShowUrlModal}}):_vm._e(),(_vm.item.groupDomain
      && _vm.showMoveModal
      && _vm.$hasPrivileges(_vm.$privileges('CAN_MOVE_ITEMS'), _vm.isSysAdmin, _vm.item.privilegesForItem))?_c('modal-move',{attrs:{"slug":_vm.item.slug,"group-domain":_vm.item.groupDomain,"group-slug":_vm.groupSlug,"slug-path-of-item":_vm.slugPath,"page-type":_vm.item.type,"item-id":_vm.item.id,"item-name":_vm.item.name,"parent-item":_vm.parentItem},on:{"item-moved":_vm.movedItem}}):_vm._e(),(_vm.item.groupDomain
      && _vm.showDuplicateModal
      && (_vm.$hasPrivileges(_vm.$privileges('CAN_DUPLICATE_ITEMS'), _vm.isSysAdmin, _vm.item.privilegesForItem)
      || _vm.isSysAdmin))?_c('modal-duplicate',{attrs:{"group-domain":_vm.item.groupDomain,"group-slug":_vm.groupSlug,"parent-item":_vm.parentItem,"item-id":_vm.item.id,"item-name":_vm.item.name,"slug":_vm.item.slug,"page-type":_vm.item.type},on:{"item-duplicated":_vm.duplicatedItem}}):_vm._e(),(_vm.canDeleteItem)?_c('modal-delete-item',{attrs:{"item":_vm.item},on:{"delete-item":_vm.deleteItem}}):_vm._e(),(_vm.$hasPrivileges(_vm.$privileges('CAN_UNPUBLISH_ITEMS'), _vm.isSysAdmin, _vm.item.privilegesForItem))?_c('modal-unpublish-item',{attrs:{"item":_vm.item},on:{"unpublish-item":_vm.unPublishItem}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }